

.slick-slide > div {
    display: grid;
    place-items: center;
    text-align: center;
    text-align: -webkit-center;
    border-radius: 3px;
}

table {
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #FDF9F9;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #FDF9F9;
  }